import React, { createContext, useContext, useState } from "react";

const AuthModalContext = createContext({});

export const useAuthModal = () => useContext(AuthModalContext);

export default function AuthModalContextProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(0);
  const [actionMode, setActionMode] = useState(null);
  const [destination, setDestination] = useState(null);
  const [successCallBack, setSuccessCallBack] = useState(() => {});

  return (
    <AuthModalContext.Provider
      value={{
        open,
        setOpen,
        active,
        setActive,
        actionMode,
        setActionMode,
        destination,
        setDestination,
        successCallBack,
        setSuccessCallBack,
      }}
    >
      {children}
    </AuthModalContext.Provider>
  );
}
