import { initializeApp, getApps } from "firebase/app";
import {
  getAuth,
  // RecaptchaVerifier
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

let app;

if (!getApps().length) {
  app = initializeApp({
    apiKey: process.env.NEXT_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
    databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_ID,
    appId: process.env.NEXT_PUBLIC_APP_ID,
  });
}
async function initialize(app) {
  // //console.log(app);
  // if (process.env.NODE_ENV === "development") {
  //   self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  // }
  // const appCheck = initializeAppCheck(app, {
  //   provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_APP_CHECK_KEY),
  //   isTokenAutoRefreshEnabled: true,
  // });
  // return appCheck;
}

if (typeof window !== "undefined" && getApps().length === 1) {
  // console.log(getApps().length);
  initialize(app)
    .then((res) => {
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
}

export const auth = getAuth(app);
export const db = getFirestore(app);
export const rtdb = getDatabase(app);
// export const messaging = typeof window !== "undefined"? getMessaging(app): null;
// export const recaptcha = RecaptchaVerifier;
export default app;
