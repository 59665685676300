import { createContext, useContext, useEffect, useState } from "react";

import { onIdTokenChanged } from "firebase/auth";
import cookie from "js-cookie";
import nookies from "nookies";
import * as Sentry from "@sentry/browser";
import { auth } from "../scripts/firebase";
import posthog from "posthog-js";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [claims, setClaims] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (user) {
        let claim = (await auth.currentUser.getIdTokenResult()).claims;
        let token = await user.getIdToken();
        setUser(user);
        setClaims(claim);

        nookies.set(undefined, "session", token, {
          maxAge: 365 * 24 * 60 * 60 * 1000,
          path: "/",
        });
        cookie.set("fast_auth", true, {
          expires: 30,
        });

        cookie.set("manager", claim.manager === true, {
          expires: 30,
        });
        cookie.set("tag", claim.user_id, {
          expires: 30,
        });
      } else {
        setUser(null);
        setClaims(null);
        // nookies.destroy(null, "session");
        nookies.set(undefined, "session", "", {
          maxAge: 365 * 24 * 60 * 60 * 1000,
          path: "/",
        });
        cookie.remove("fast_auth");
        cookie.remove("manager");
        cookie.remove("tag");
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, [setClaims, setUser]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = auth.currentUser;
      if (user)
        user.getIdToken(true).then((token) => {
          setUser(user);
          // nookies.destroy(null, "session");
          nookies.set(undefined, "session", token, {
            maxAge: 365 * 24 * 60 * 60 * 1000,
            path: "/",
          });
          cookie.set("fast_auth", true, {
            expires: 1,
          });
        });
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, []);

  const signout = async () => {
    posthog.reset();
    Sentry.setUser(null);
    return await auth.signOut();
  };
  return (
    <AuthContext.Provider value={{ user, claims, loading, signout }}>
      {children}
    </AuthContext.Provider>
  );
}
