import { useRouter } from "next/router";
import { useAuthModal } from "./auth/modalcontext";
import Link from "next/link";
import { AiFillInstagram, AiTwotoneStar } from "react-icons/ai";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import Image from "next/image";
import { useSideNav } from "./auth/sidenavcontext";
import { FaArrowRight } from "react-icons/fa";
import { useAuth } from "../auth/AuthProvider";
// import { BiLogoWhatsapp } from "react-icons/bi";
import { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";
import cookie from "js-cookie";
import dynamic from "next/dynamic";
import { motion } from "framer-motion";

const MainNav = dynamic(() => import("./nav/MainNav"));
const SideNavDialog = dynamic(() => import("./auth/sidenavmodal"));
const DashNavAndSide = dynamic(() => import("./nav/DashNavAndSide"));
const AuthDialog = dynamic(() => import("./auth/authmodal"));
const ManagerNav = dynamic(() => import("./nav/ManagerNav"));

export default function Layout({ children }) {
  const router = useRouter();
  const authModal = useAuthModal();
  const sideNavModal = useSideNav();

  const contextAuth = useAuth();
  const user = contextAuth.user;
  const claims = contextAuth.claims;

  const [color, setColor] = useState("blue");
  const [topBannerShown, setTopBannerShown] = useState(true);

  useEffect(() => {
    setColor("red");
    getApps();
  }, []);

  const [appInstalled, setAppInstalled] = useState(false);

  const getApps = async () => {
    try {
      let apps = await navigator.getInstalledRelatedApps();
      if (apps.length > 0) {
        setAppInstalled(apps[0].id === "com.ulaafrica.app");
      } else {
        setAppInstalled(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const topBanner = cookie.get("top_banner");
  // console.log(children.props.children.twn)
  // console.log(topBanner);

  const arrowVariants = {
    animate: {
      x: [0, 10, 0],
      transition: {
        repeat: 2,
        duration: 2.2,
      },
    },
  };
  const {
    // openNav,
    // setOpenNav,
    open,
    setOpen,
    active,
    setActive,
    actionMode,
    setActionMode,
    destination,
    setDestination,
    successCallBack,
    setSuccessCallBack,
  } = authModal;
  return (
    <>
      {router.pathname !== "/list-a-property" &&
      router.pathname !== "/hodari" ? (
        <>
          {!router.pathname.startsWith("/lister") &&
          // !router.pathname.startsWith("/sales-team") &&
          !router.pathname.startsWith("/account") &&
          !router.pathname.startsWith("/referral") ? (
            <div className=" hidden w-full flex-row items-center justify-center space-x-2 bg-yellow-600 px-4  py-3 lg:h-12 lg:space-x-2 lg:px-32 lg:py-0 ">
              <AiTwotoneStar className=" text-ula-primary" />

              <span className=" flex cursor-default flex-row   text-sm font-semibold text-zinc-900">
                Are you a real estate agent?{" "}
                <span className=" ml-1 hidden lg:flex ">
                  Reach more buyers and renters.
                </span>
                <Link href="/hodari" passHref>
                  <a className=" ml-1 cursor-pointer  underline underline-offset-2">
                    Sign up
                  </a>
                </Link>
              </span>
            </div>
          ) : null}
        </>
      ) : null}
      {(topBanner === undefined || topBanner !== "hide") &&
      topBannerShown &&
      color === "red" ? (
        <>
          {router.pathname.startsWith("/town") ||
          // router.pathname === "/" ||
          router.pathname.startsWith("/homes") ? (
            <div className="  z-10 flex w-full flex-row items-center justify-between space-x-2 border border-b border-t-0 border-gray-200 bg-white  px-3 py-2  lg:hidden  ">
              <div className=" flex flex-row items-center space-x-1">
                <IoIosClose
                  onClick={() => {
                    setTopBannerShown(false);
                    cookie.set("top_banner", "hide", {
                      expires: appInstalled ? 90 : 15,
                    });
                  }}
                  className=" h-6 w-6 text-gray-700"
                />
                <div className=" flex flex-col">
                  <span className=" text-sm font-semibold">
                    {color === "red" &&
                    /iPad|iPhone|iPod/.test(navigator?.userAgent)
                      ? "Ula Find Homes"
                      : "Ula Homes App: Easy home search"}
                  </span>
                  <span className=" text-xs text-gray-700">
                    {appInstalled
                      ? "Find homes in the app"
                      : "Explore more homes in the app"}
                  </span>
                </div>
              </div>
              {appInstalled ? (
                <a
                  href="https://homes.ula.africa/app"
                  className="  rounded bg-ula-primary px-4  py-2 text-sm font-medium  text-gray-150 hover:bg-lightblue-700"
                >
                  Open
                </a>
              ) : (
                <a
                  href={
                    color === "red" &&
                    /iPad|iPhone|iPod/.test(navigator?.userAgent)
                      ? "https://apps.apple.com/ke/app/ula-find-homes/id6452316629"
                      : "https://play.google.com/store/apps/details?id=com.ulaafrica.app"
                  }
                  className="  rounded bg-ula-primary px-4  py-2 text-sm font-medium  text-gray-150 hover:bg-lightblue-700"
                >
                  Install
                </a>
              )}
            </div>
          ) : null}
        </>
      ) : null}

      {
        //router.pathname === "/list-a-property" ||
        router.pathname === "/hodari" ||
        router.pathname.startsWith("/lister") ? (
          router.pathname.startsWith(
            "/lister/listing"
          ) ? null : router.pathname.startsWith("/lister/dashboard") ? (
            // &&
            //   !router.pathname === "/lister/dashboard/w"
            <DashNavAndSide>{children}</DashNavAndSide>
          ) : (
            <ManagerNav />
            // <></>
          )
        ) : router.pathname.startsWith("/admin/") ? null : (
          <MainNav
            props={children}
            setOpenNav={sideNavModal.setOpenNav}
            openNav={sideNavModal.openNav}
            setOpen={setOpen}
            open={open}
            active={active}
            setActive={setActive}
            actionMode={actionMode}
            setActionMode={setActionMode}
            destination={destination}
            setDestination={setDestination}
            successCallBack={successCallBack}
            setSuccessCallBack={setSuccessCallBack}
          />
        )
      }

      <AuthDialog
        setOpen={setOpen}
        open={open}
        active={active}
        setActive={setActive}
        actionMode={actionMode}
        setActionMode={setActionMode}
        destination={destination}
        setDestination={setDestination}
        successCallBack={successCallBack}
        setSuccessCallBack={setSuccessCallBack}
      />

      <SideNavDialog
        setOpenNav={sideNavModal.setOpenNav}
        openNav={sideNavModal.openNav}
        setOpen={setOpen}
        open={open}
      />

      {!router.pathname.startsWith("/lister") && user && claims.manager ? (
        <Link href="/lister/dashboard">
          <div className=" fixed bottom-0 z-20 flex h-32 w-full items-center justify-center bg-transparent">
            <button className=" group flex flex-row items-center space-x-2 rounded-full bg-ula-corn  px-12 py-4 text-lg font-medium text-gray-900 shadow-xl duration-300 hover:scale-105 ">
              <span>Go to Agent Dashboard</span>
              <motion.div variants={arrowVariants} animate="animate">
                <FaArrowRight className="   " />
              </motion.div>
            </button>
          </div>
        </Link>
      ) : null}

      {router.pathname.startsWith("/lister/dashboard") ? null : <>{children}</>}

      {router.pathname.startsWith("/admin") ||
      router.pathname.startsWith("/town") ||
      (router.pathname.startsWith("/lister") &&
        !router.pathname.startsWith("/lister/claim-profile") &&
        !router.pathname.startsWith("/lister/pricing")) ? null : (
        <footer className="flex flex-col justify-center space-x-4  space-y-3 divide-y divide-gray-300    bg-gray-200 px-0 pb-2 pt-4 text-gray-900 lg:space-y-24 lg:px-20 lg:pt-32">
          <div className=" grid h-auto grid-cols-1 gap-y-8 px-4 py-16 lg:h-auto lg:grid-cols-5 lg:gap-x-5 lg:gap-y-0 lg:py-0">
            <div className="flex flex-col space-y-0 lg:space-y-1">
              <div className=" flex flex-row items-center space-x-1 lg:items-center lg:space-x-2">
                <div className=" flex h-8 w-8 items-center justify-center rounded-full bg-ula-primary lg:h-12 lg:w-12 lg:bg-ula-primary">
                  <div className=" relative   h-6  w-6 rounded-full bg-ula-primary lg:h-8 lg:w-8 lg:bg-ula-primary ">
                    <Image
                      src="/icon/ula.png"
                      layout="fill"
                      className=" flex h-5 w-5 object-contain lg:hidden "
                      alt=""
                    />
                  </div>
                </div>
                {/* <div className=" relative -mt-1 h-8 w-8 lg:mt-0 lg:h-10 lg:w-10">
                  <Image
                    src="/icon/ula-dark.png"
                    layout="fill"
                    className=" hidden h-5 w-5 object-contain lg:flex"
                    alt=""
                  />
                </div> */}
                <Link href="/" prefetch={false} passHref>
                  <a
                    title={router.pathname === "/" ? "" : "Home"}
                    className=" mt-2 cursor-pointer font-eastman text-2xl  lg:text-3xl"
                  >
                    ula
                  </a>
                </Link>
              </div>
              <span>Home search, simplified</span>

              <div className=" flex w-full flex-row  items-center space-x-4 ">
                <a
                  href="https://apps.apple.com/ke/app/ula-find-homes/id6452316629"
                  className="relative  flex  h-12 w-32"
                >
                  <Image
                    className=" object-contain  "
                    src="/image/app-store-logo.svg"
                    layout="fill"
                    alt="Ula iOS App"
                  />
                </a>
                <a
                  href="http://play.google.com/store/apps/details?id=com.ulaafrica.app"
                  className=" relative h-16 w-40 lg:h-[3.0rem] lg:w-32"
                >
                  <Image
                    className="  h-full w-full  object-cover  "
                    src="/image/play-badge.png"
                    layout="fill"
                    alt="Ula Android App"
                  />
                </a>
              </div>
            </div>
            <div className=" flex  h-auto flex-col space-y-2 lg:pl-2">
              <h3 className=" text-xl font-semibold">Platform</h3>
              <div className=" flex flex-col space-y-2 text-gray-800 lg:space-y-3">
                <Link href="/ula-app" passHref>
                  <a>
                    <h4 className=" cursor-pointer hover:underline">
                      Mobile app
                    </h4>
                  </a>
                </Link>
                <Link href="/list-a-property" passHref>
                  <a>
                    <h4 className=" cursor-pointer hover:underline">
                      List your Property
                    </h4>
                  </a>
                </Link>
                <Link href="/hodari" passHref>
                  <a className="flex flex-row space-x-2">
                    <h4 className=" cursor-pointer hover:underline">
                      Hodari Agent
                    </h4>
                    {/* <span className=" rounded-full  bg-ula-primary px-3 py-1 text-xs font-medium uppercase text-gray-100 shadow-sm">
                      New
                    </span> */}
                  </a>
                </Link>
                <Link href="/lister/claim-profile" passHref>
                  <a className="flex flex-row space-x-2">
                    <h4 className=" cursor-pointer hover:underline">
                      Claim Company Profile
                    </h4>
                  </a>
                </Link>
                <Link href="/real-estate-agency-near-me" passHref>
                  <a>
                    <h4 className=" cursor-pointer hover:underline">
                      Find an Agent
                    </h4>
                  </a>
                </Link>
                <Link href="/real-estate-agency-near-me" passHref>
                  <a>
                    <h4 className=" cursor-pointer hover:underline">
                      Find a Real Estate Company
                    </h4>
                  </a>
                </Link>

                {/* <span className=" cursor-pointer hover:underline">Kasha</span> */}
              </div>
            </div>
            <div className=" flex  h-auto flex-col space-y-2">
              <h3 className=" text-xl font-semibold">Tools</h3>
              <div className=" flex flex-col space-y-2 text-gray-800 lg:space-y-3">
                <Link href="/calculators/rent-budget-calculator" passHref>
                  <a className=" flex flex-col">
                    <h4 className=" cursor-pointer hover:underline">
                      Rent Budget Calculator
                    </h4>
                  </a>
                </Link>
                <Link href="/calculators/mortgage-calculator" passHref>
                  <a className=" flex flex-col">
                    <h4 className=" cursor-pointer hover:underline">
                      Mortgage Calculator
                    </h4>
                    <div>
                      <span className=" cursor-pointer rounded-full  bg-ula-primary px-3 py-1 text-xs font-medium uppercase text-gray-200">
                        Popular
                      </span>
                    </div>
                  </a>
                </Link>
              </div>
            </div>
            <div className=" flex  h-auto flex-col space-y-2">
              <h3 className=" text-xl font-semibold">Resources</h3>
              <div className=" flex flex-col space-y-2 text-gray-800 lg:space-y-3">
                <Link href="/blog" passHref>
                  <a>
                    <h4 className=" cursor-pointer hover:underline">Blog</h4>
                  </a>
                </Link>

                <Link href="/referral" passHref>
                  <a>
                    <h4 className=" cursor-pointer hover:underline">
                      Refer an Agent
                    </h4>
                  </a>
                </Link>
                <Link href="/referral" passHref>
                  <a>
                    <h4 className=" cursor-pointer hover:underline">
                      Refer a Company
                    </h4>
                  </a>
                </Link>
              </div>
            </div>
            <div className=" flex  h-auto flex-col space-y-2">
              <h3 className=" text-xl font-semibold">Contact</h3>
              <div className=" flex flex-col space-y-2 text-gray-800 lg:space-y-3">
                {/* <a
                  title="Whatsapp our team"
                  target="_blank"
                  rel="nofollow noreferrer"
                  href={`https://api.whatsapp.com/send?phone=254797661478`}
                  className=" group flex cursor-pointer flex-row space-x-1  "
                >
                  <BiLogoWhatsapp className=" h-6 w-6 text-gray-900  group-hover:text-green-500" />
                  <span className="  decoration-green-500 group-hover:underline">
                    0797 661 478
                  </span>
                </a> */}
                <a
                  href="mailto:hello@ula.africa"
                  className=" cursor-pointer hover:underline "
                >
                  hello@ula.africa
                </a>

                <div className=" flex flex-row items-center space-x-6 pr-8 lg:space-x-4">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://facebook.com/@ulafrica`}
                  >
                    <BsFacebook className=" h-6 w-6 cursor-pointer" />
                  </a>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://instagram.com/ulaafrica`}
                  >
                    <AiFillInstagram className=" h-[1.7rem] w-[1.7rem] cursor-pointer" />
                  </a>

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://linkedin.com/company/ularealafrica`}
                  >
                    <BsLinkedin className=" h-6 w-6 cursor-pointer" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex  h-20 items-center justify-between ">
            <span className=" hidden lg:block ">
              &#169;
              <span className="ml-1">{new Date().getFullYear()}</span>
              <Link href="/" prefetch={false} passHref>
                <a className=" ml-1 cursor-pointer">Ula. All rights reserved</a>
              </Link>{" "}
            </span>
            <div className=" flex flex-row space-x-2">
              <Link prefetch={false} href="/privacy" passHref>
                <a className=" cursor-pointer ">Privacy Policy</a>
              </Link>
              <Link prefetch={false} href="/terms-and-conditions" passHref>
                <a className=" cursor-pointer ">Terms and Conditions</a>
              </Link>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}
