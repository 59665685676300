import "../styles/tailwind.css";
import "../styles/index.css";
import dynamic from "next/dynamic";
import Layout from "../components/layout";

const AuthModalContextProvider = dynamic(() =>
  import("../components/auth/modalcontext")
);
const NextProgress = dynamic(() => import("next-progress"), {
  ssr: false,
});
const AuthContextProvider = dynamic(() => import("../auth/AuthProvider"), {});
import { useEffect, useState } from "react";
import { load } from "@fingerprintjs/botd";
import { useRouter } from "next/router";
// const FacebookPixel = dynamic(() => import("../components/trackfb/FbPixel"));
const SideNavContextProvider = dynamic(() =>
  import("../components/auth/sidenavcontext")
);

import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import * as FullStory from "@fullstory/browser";
import { useAuth } from "../auth/AuthProvider";

const isProd = process.env.NODE_ENV === "production";

if (typeof window !== "undefined") {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    loaded: (posthog) => {
      if (!isProd || process.env.NEXT_PUBLIC_VERCEL_ENV === "preview")
        posthog.debug(); // debug mode in development
    },
    // debug: !isProd || process.env.NEXT_PUBLIC_VERCEL_ENV === "preview",
    autocapture: false,
  });
}

function MyApp({ Component, pageProps }) {
  const [fbot, setFbot] = useState(false);
  const [inBrowser, setInBrowser] = useState(true);

  const contextAuth = useAuth();
  const user = contextAuth.user;

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
      FullStory.init({ orgId: "o-1RF27W-na1" });
    }
  }, []);
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production" && user) {
      FullStory.identify(user.uid, { email: user.email });
    }
  }, [user]);

  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    load()
      .then((botd) => {
        return botd.detect();
      })
      .then((result) => {
        //console.log(result.bot)
        setFbot(result);
      })
      .catch((error) => console.error(error));
    setInBrowser(false);
    return () => {};
  }, [inBrowser]);

  // useEffect(() => {
  //   setToken();
  //   async function setToken() {
  //     try {
  //       const token = await enableMessaging();
  //       if (token) {
  //         getMessage();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   function getMessage() {
  //     const messaging = getMessaging();
  //     onMessage(messaging, (payload) => {
  //       // console.log("Message received. ", payload);
  //       // ...
  //     });
  //   }
  // }, []);

  return (
    <>
      {/* {process.env.NEXT_PUBLIC_VERCEL_ENV === "production" ? (
        <FacebookPixel />
      ) : null} */}
      {/* <GoogleReCaptchaProvider
        reCaptchaKey={
          process.env.NODE_ENV === "development"
            ? ""
            : process.env.NEXT_PUBLIC_RECAPTCHA_KEY
        }
        scriptProps={{
          async: true, // optional, default to false,
          defer: true,
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined,
        }}
      > */}
      {/* #FFE7AB */}
      {/* <PushNotificationLayout> */}
      <PostHogProvider client={posthog}>
        <NextProgress
          height={router.pathname.startsWith("/lister") ? "2.35px" : "2px"}
          color={router.pathname.startsWith("/lister") ? "#0747a6" : "#020057"}
          disableSameRoute
          options={{ showSpinner: process.env.NODE_ENV === "development" }}
        />
        <SideNavContextProvider>
          <AuthModalContextProvider>
            <AuthContextProvider>
              <Layout>
                <div id="root">
                  <Component {...pageProps} />
                  {/* <Analytics /> */}
                </div>
              </Layout>
            </AuthContextProvider>
          </AuthModalContextProvider>
        </SideNavContextProvider>
      </PostHogProvider>
      {/* </PushNotificationLayout> */}
      {/* </GoogleReCaptchaProvider> */}
    </>
  );
}
export default MyApp;
