import React, { createContext, useContext, useState } from "react";

const SideNavContext = createContext({});

export const useSideNav = () => useContext(SideNavContext);

export default function SideNavContextProvider({ children }) {
  const [openNav, setOpenNav] = useState(false);
  const [active, setActive] = useState(0);
  const [actionMode, setActionMode] = useState(null);
  const [destination, setDestination] = useState(null);
  const [successCallBack, setSuccessCallBack] = useState(() => {});

  return (
    <SideNavContext.Provider
      value={{
        openNav,
        setOpenNav,
        active,
        setActive,
        actionMode,
        setActionMode,
        destination,
        setDestination,
        successCallBack,
        setSuccessCallBack,
      }}
    >
      {children}
    </SideNavContext.Provider>
  );
}
